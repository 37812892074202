<template>
  <div id="app" class="h-screen w-full flex flex-col bg-body-bg">
    <header class="[ w-full py-4 md:py-7 ] [ flex items-center justify-center ] border-b border-primary-1-10">
      <div class="w-full flex justify-center md:justify-between items-center [ container mx-auto ]">
        <div class="hidden lg:inline-flex items-center justify-center">
          <svg-icon-guard class="w-6 h-6" />
          <span class="ml-2 inline">{{ $t('secureCheckout') }}</span>
        </div>

        <AppLink to="/" class="w-25 md:w-36 h-4 md:h-5" aria-label="karaca home">
          <KaracaLogo />
        </AppLink>

        <div class="hidden lg:flex gap-x-12 items-center">
          <AppLink to="/contact" class="flex items-center justify-between">
            {{ $t('customerSupport') }}
          </AppLink>

          <a
            :href="switchLocalePathWithParams($i18n.locale === 'en' ? 'ar' : 'en')"
            :class="[$i18n.locale === 'en' ? 'font-body-ar font-semibold' : 'font-body']"
          >
            {{ $t('switchLang') }}
          </a>
        </div>
      </div>
    </header>

    <div id="after-header" />
    <div id="after-header-fade" transition="TransitionFade" />
    <div id="after-header-slide" transition="TransitionSlideUp" />

    <main class="flex-grow">
      <slot />
    </main>

    <ClientOnly>
      <ModalVerifyOtp />
    </ClientOnly>

    <AlertGroup />

    <footer class="z-0 bg-primary-1-05 flex flex-col items-center justify-center text-center lg:text-left">
      <AppLink to="/contact" class="w-full flex md:hidden px-6 py-5 items-center justify-between">
        <span class="ml-2 inline">{{ $t('customerSupport') }}</span>
        <svg-icon-chveron-right
          class="w-4 h-4 lg:w-8 lg:h-8"
          :class="{
            'transform scale-reverse': $i18n.locale === 'ar',
          }"
        />
      </AppLink>

      <div class="w-full flex border-b border-primary-1-15 md:hidden px-6 py-5 items-center justify-between">
        <span class="ml-2 inline capitalize">{{ $t('changeLanguage') }}</span>

        <a
          :href="switchLocalePathWithParams($i18n.locale === 'en' ? 'ar' : 'en')"
          :class="[$i18n.locale === 'en' ? 'font-body-ar font-semibold' : 'font-body']"
        >
          {{ $t('switchLang') }}
        </a>
      </div>

      <div class="bg-primary-1-100 w-full flex flex-col py-6 lg:py-3 items-center justify-center">
        <i18n-t tag="p" keypath="privacyNote" class="px-6 lg:px-0 text-white">
          <template #privacy>
            <a href="/privacy-policy" target="_blank" rel="noopener" class="underline">
              {{ $t('privacyPolicy') }}
            </a>
          </template>
        </i18n-t>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import type { Organization, WebSite } from 'schema-dts';

const { t: $t } = useI18n({
  useScope: 'local',
});

const switchLocalePath = useSwitchLocalePath();
const route = useRoute();

// To keep query params if exists
function switchLocalePathWithParams(locale: string) {
  const queryParams = route.fullPath.split('?')[1] ? `?${route.fullPath.split('?')[1]}` : '';
  return `${switchLocalePath(locale)}${queryParams}`;
}

useSeoLinks();

const {
  public: { apiUrl: url },
} = useRuntimeConfig();

// https://developers.google.com/search/docs/data-types/sitelinks-searchbox
const websiteWithSearchAction: WebSite = {
  '@type': 'WebSite',
  url,
  potentialAction: {
    '@type': 'SearchAction',
    target: `${url}/search?q={search_term_string}`,
    query: 'required name=search_term_string',
  },
};

const org: Organization = {
  '@id': url,
  '@type': 'Organization',
  // TODO: Organization name
  name: 'Unique',
  url,
  logo: `${url}/img/logo.png`,
  sameAs: [
    // TODO: Any social links to facebook/twitter/whatever
  ],
};

useJsonld({
  '@context': 'https://schema.org',
  '@graph': [websiteWithSearchAction, org],
});
</script>
<style lang="postcss" scoped>
@screen md {
  .header-container {
    display: grid;
    grid-template-columns: 1fr 170px 1fr;
  }
}
</style>
<i18n>
{
  "en": {
    "privacyNote": "Your entire personal data is protected and will be treated in accordance with our {privacy}",
    "privacyPolicy": "Privacy Policy",
    "secureCheckout": "Secure Checkout",
    "switchLang": "عربي",
    "changeLanguage": "Change language",
    "customerSupport": "Customer Support"
  },
  "ar": {
    "privacyNote": "تتم حماية بياناتك الشخصية بالكامل وسيتم التعامل معها وفقًا لموقعنا {privacy}",
    "privacyPolicy": "سياسة الخصوصية",
    "secureCheckout": "الدفع الآمن",
    "switchLang": "English",
    "changeLanguage": "تغيير اللغه",
    "customerSupport": "دعم العملاء"
  }
}
</i18n>
